.alt {
    background: #eae0c9;
}

.centered {
    text-align: center;
}

.uploader {
    height: 50vh !important;
}
